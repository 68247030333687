import "./app.scss";
import { ButtonGroup, SparePartsList } from 'ui';
import { SearchResultModel } from "./types";
import { useCallback } from "react";

const urlParams = new URLSearchParams(window.location.search);

const navigateToSecondary = (secondary: boolean) => {
    if (secondary) {
        urlParams.set('secondary', 'true');
    } else {
        urlParams.delete('secondary');
    }
    window.location.search = urlParams.toString();
}
export default function App() {
    const model = window.app.preloadState.searchResultModel;
    const translation = window.app.preloadState.translation;

    const isSecondaryResults = urlParams.get('secondary') === 'true';

    return <div className="page">
        {
        model.noQuery ? (
            <NoQuery model={model} />
        ) : (
            model.noHits ? (
                <NoHits model={model} />
            ) : (
                model.allResultsSearch ? (
                    <AllResults model={model} translation={translation} />
                ) : (
                    <>
                        <div className="pageHeader">
                            <h1>{model.query}</h1>
                            <ButtonGroup initialSelected={isSecondaryResults}
                                onChecked={navigateToSecondary}
                                items={[
                                    { label: translation["navigationTypes.matchingMachine"], value: false },
                                    { label: translation["navigationTypes.notMatchingMachine"], value: true },
                                ]} />
                        </div>
                        {isSecondaryResults
                            ? <NotMatchingResults model={model} translation={translation} />
                            : <MatchingResults model={model} translation={translation} />
                        }
                    </>
                    )
                )
            )
        }
    </div>
}

function NoQuery({ model }: { model: SearchResultModel }) {
    return <>
        <div className="pageHeader">
            <h1>{model.resultPage.noQueryTitle}</h1>
        </div>
        {model.resultPage.noQueryDescription &&
            <div className="machineSpacer">
                <div>{model.resultPage.noQueryDescription}</div>
                <div>{model.resultPage.refineSearchNote}</div>
            </div>
        }
    </>
}

function NoHits({ model }: { model: SearchResultModel }) {
    return <>
        <div className="pageHeader">
            <h1>{model.query}</h1>
        </div>
        <h2>{model.resultPage.noHitsTitle}</h2>
        {model.resultPage.noHitsDescription &&
            <div>{model.resultPage.noHitsDescription}</div>
        }
    </>
}

function AllResults({ model, translation }: { model: SearchResultModel, translation: Record<string, string> }) {

    const callback = useCallback(async (page: number) => {
        const params = new URLSearchParams();
        params.set('searchQuery', model.query);
        params.set('pageNumber', page.toString());
        const response = await fetch(`/api/search/all?${params.toString()}`, {
            method: "GET",
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            }
        });
        return (await response.json()).items;
    }, [model.query]);

    return <>
        <div className="pageHeader">
            {model.query && <h1>"{model.query}"</h1>}
            {!model.query && model.machineName && <h1>{model.machineName}</h1>}
        </div>
        <div className="machineSpacer">
            <div>{`${model.allResultsHits} ${translation["searchResultTranslations.foundResults"]}`}</div>
            <div>{model.resultPage.refineSearchNote}</div>
        </div>
        <SparePartsList items={model.allResults} total={model.allResultsHits} pageCallback={callback} />
    </>
}

function MatchingResults({ model, translation }: { model: SearchResultModel, translation: Record<string, string> }) {

    const callback = useCallback(async (page: number) => {
        const params = new URLSearchParams();
        params.set('searchQuery', model.query);
        params.set('pageNumber', page.toString());
        const response = await fetch(`/api/search/matching?${params.toString()}`, {
            method: "GET",
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            }
        });
        return (await response.json()).items;
    }, [model.query]);

    return <>
        <div className="machineSpacer">
            <span>{`${model.machineMatchedHits} ${translation["searchResultTranslations.foundResults"]}`}</span>
            <div>{model.resultPage.refineSearchNote}
            {model.machineNotMatchedResults &&
                <>
                    <span> / </span>
                    <span onClick={() => navigateToSecondary(true)} className="machineResultsSecondary machineResultsLink">{`${model.machineNotMatchedHits} ${translation["searchResultTranslations.notMatchingMachineResults"]}`}</span>
                    <span className="machineResultsTooltip">
                        <span className="machineResultsTooltipIcon"></span>
                        <span className="firstLetterCapital">{translation["searchResultTranslations.notMatchingMachineResultsTooltip"]}</span>
                    </span>
                </>
                }
            </div>
        </div>
        <SparePartsList items={model.machineMatchedResults} total={model.machineMatchedHits} pageCallback={callback} />
    </>
}

function NotMatchingResults({ model, translation }: { model: SearchResultModel, translation: Record<string, string> }) {

    const callback = useCallback(async (page: number) => {
        const params = new URLSearchParams();
        params.set('searchQuery', model.query);
        params.set('pageNumber', page.toString());
        const response = await fetch(`/api/search/not-matching?${params.toString()}`, {
            method: "GET",
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            }
        });
        return (await response.json()).items;
    }, [model.query]);

    return <>
        <div className="machineSpacer">
            <span className="machineResultsSecondary">{`${model.machineNotMatchedHits} ${translation["searchResultTranslations.notMatchingMachineResultsTooltip"]}`}</span>
            <div>{model.resultPage.refineSearchNote}
            <span className="machineResultsTooltip">
                <span className="machineResultsTooltipIcon"></span>
                <span className="firstLetterCapital">{translation["searchResultTranslations.notMatchingMachineResultsTooltip"]}</span>
                </span>
            </div>
        </div>
        <SparePartsList items={model.machineNotMatchedResults} total={model.machineNotMatchedHits} pageCallback={callback} />
    </>
}